import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';

import css from './NoticeCard.module.scss';
import { Hit } from 'models';
import { NOTICES } from 'shared-config';

import { buildUrl } from 'shared/http-common';
import ChipLine from './chipLine/ChipLine';
import ThresholdIcon from './thresholdIcon/ThresholdIcon';
import IconLine from './iconLine/IconLine';

interface Props {
  readonly notice: Hit;
  readonly isFirstInList: boolean;
}

export default function NoticeCard({
  notice,
  isFirstInList
}: Props): ReactElement {
  const { t } = useTranslation('translations');
  const { id, heading, buyer, status, description, type, allTypes, sentToTed } =
    notice;

  const [searchParams] = useSearchParams();

  const isCompetition = allTypes.includes('COMPETITION');

  const getBuyerNames = () => buyer.map((b) => b.name).join(', ');

  return (
    <li data-cy={heading}>
      <Link
        id={isFirstInList ? 'notice-link' : undefined}
        to={buildUrl([NOTICES, id])}
        className={css.card}
        state={{ queryString: searchParams.toString() }}
      >
        <div className={css.header}>
          <p
            className={css.buyer}
            aria-label={`${t('common.buyer')}: ${getBuyerNames()}`}
          >
            {getBuyerNames()}
          </p>
          <ThresholdIcon isSentToTed={sentToTed} />
        </div>
        <h2
          className={css.title}
          data-cy="notice-card-title"
          aria-label={`${t('search.card.title')}: ${heading}`}
        >
          {heading}
        </h2>
        <p
          className={css.ingress}
          data-cy="notice-card-description"
          aria-label={`${t('search.card.description')}: ${description}`}
        >
          {description}
        </p>
        <div className={css.bottom_line}>
          <ChipLine status={status} type={type} allTypes={allTypes} />
          <IconLine notice={notice} isCompetition={isCompetition} />
        </div>
      </Link>
    </li>
  );
}
