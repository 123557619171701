import React, { ReactElement } from 'react';
import { SuggestItem } from 'models';
import css from '../SuggestionList.module.scss';
import { useTranslation } from 'react-i18next';
import { formatNumbers } from 'shared/utils';
import classNames from 'classnames';

interface Props {
  readonly item: SuggestItem;
  readonly focused: boolean;
  readonly onClick: () => void;
  readonly focusedIndex: number;
  readonly categoryName: string;
  readonly query: string;
}

const highlightMatchedText = (text: string, query: string) => {
  const regex = new RegExp(`(${query})`, 'gi');
  return text.replace(regex, '<strong>$1</strong>');
};

export default function SuggestionItem(props: Props): ReactElement {
  const { item, focused, onClick, focusedIndex, categoryName, query } = props;
  const { t } = useTranslation('translations');

  let textValue =
    categoryName.includes('CPV') && item.value.includes('null - ')
      ? item.id + ' - ' + item.value.substring(7)
      : item.value;
  if (categoryName === 'cpvCodesLabel') {
    textValue = item.id + ' - ' + textValue;
  }
  const ariaLabel =
    t('common.' + categoryName) +
    ':' +
    item.value +
    ' ' +
    item.total +
    ' ' +
    t('search.result');

  return (
    <li
      aria-label={ariaLabel}
      id={'option_' + focusedIndex}
      role="option"
      tabIndex={-1}
      aria-selected={focused}
      onClick={onClick}
      className={classNames(css.list_item, focused && css.focused)}
      lang="nb"
      onKeyDown={(event) => event.key === 'Enter' && onClick()}
    >
      <span
        className={css.bold}
        dangerouslySetInnerHTML={{
          __html:
            highlightMatchedText(textValue, query) +
            formatNumbers(` (${item.total.toString()})`)
        }}
      />
    </li>
  );
}
