import React, { ReactElement, useMemo, useState } from 'react';
import styles from './ProcurementTimeline.module.scss';
import { ReactComponent as Document } from '../../../../images/icon/documents-dblue.svg';
import { ReactComponent as DocumentFaded } from '../../../../images/icon/documents-faded.svg';
import { ReactComponent as ChevronUp } from '../../../../images/icon/chevron-up-white.svg';
import { ReactComponent as ChevronDown } from '../../../../images/icon/chevron-down-white.svg';
import { ReactComponent as ArrowUp } from '../../../../images/icon/doublearrow-up.svg';
import { ReactComponent as ArrowDown } from '../../../../images/icon/doublearrow-down.svg';
import { ReactComponent as ArrowRight } from '../../../../images/icon/arrow-right-lblue.svg';

import { ProcurementTimelineData } from '../../../../models';
import { buildUrl } from 'shared/http-common';
import { NOTICES } from 'shared-config';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { formatDate } from '../../../../shared/utils';

interface Props {
  readonly procurementTimeline?: ProcurementTimelineData[];
}

const ProcurementTimeline = ({
  procurementTimeline = []
}: Props): ReactElement => {
  const { t } = useTranslation('translations');
  const flattenedTimeline = procurementTimeline.flat();

  const typeMap: Record<string, { title: string; linkName: string }> = useMemo(
    () => ({
      PLANNING: {
        title: t('type.planning'),
        linkName: t('subtype.advisory_notice')
      },
      COMPETITION: {
        title: t('type.competition'),
        linkName: t('subtype.announcement_of_competition')
      },
      RESULT: {
        title: t('type.result'),
        linkName: t('subtype.announcement_of_conclusion_of_contract')
      }
    }),
    [t]
  );

  const accordionData = useMemo(() => {
    const data = flattenedTimeline.reduce(
      (acc, item) => {
        const type = Array.isArray(item.allType) ? item.allType[1] : null;

        if (type && typeMap[type]) {
          const { title, linkName } = typeMap[type];
          if (!acc[title]) {
            acc[title] = {
              type,
              title,
              links: []
            };
          }

          acc[title].links.push({
            name: linkName,
            date: item.publicationDate,
            activePage: item.selectedNotice,
            id: item.id
          });
        }

        return acc;
      },
      {} as Record<
        string,
        {
          type: string;
          title: string;
          links: {
            name: string;
            date: string;
            activePage: boolean;
            id: string;
          }[];
        }
      >
    );

    ['PLANNING', 'COMPETITION', 'RESULT'].forEach((phase) => {
      const title = typeMap[phase].title;
      if (!data[title]) {
        data[title] = { type: phase, title, links: [] };
      }
    });

    return Object.values(data).sort((a, b) => {
      const orderMap = { PLANNING: 0, COMPETITION: 1, RESULT: 2 };
      return orderMap[a.type] - orderMap[b.type];
    });
  }, [flattenedTimeline, typeMap]);

  const initialExpandedIndex = useMemo(
    () =>
      accordionData.findIndex((item) =>
        item.links.some((link) => link.activePage)
      ),
    [accordionData]
  );

  const [expandedIndexes, setExpandedIndexes] = useState<number[]>(
    initialExpandedIndex !== -1 ? [initialExpandedIndex] : []
  );

  const handleToggle = (index: number) => {
    setExpandedIndexes((prevIndexes) =>
      prevIndexes.includes(index)
        ? prevIndexes.filter((i) => i !== index)
        : [...prevIndexes, index]
    );
  };

  return (
    <div className={styles.timelineContainer}>
      <h2 className={styles.timelineTitle}>{t('timeline.heading')}</h2>
      <div className={styles.accordion}>
        {accordionData.map((item, index) => (
          <AccordionItem
            key={index}
            {...item}
            isActive={expandedIndexes.includes(index)}
            onToggle={() => handleToggle(index)}
          />
        ))}
      </div>
    </div>
  );
};

const AccordionItem = ({
  title,
  links,
  type,
  isActive,
  onToggle
}: {
  title: string;
  links: { name: string; date: string; activePage: boolean; id: string }[];
  type: string;
  isActive: boolean;
  onToggle: () => void;
}) => {
  const [showAll, setShowAll] = useState(false);
  const [hoveredLinkIndex, setHoveredLinkIndex] = useState<number | null>(null);
  const { t } = useTranslation('translations');

  const toggleShowAll = () => setShowAll(!showAll);
  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  };

  const sortedLinks = useMemo(
    () =>
      [...links].sort(
        (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
      ),
    [links]
  );

  const displayedLinks = showAll ? sortedLinks : sortedLinks.slice(0, 5);

  const getLabel = (type: string, index: number, linksLength: number) => {
    if (linksLength <= 1) return '';
    if (type === 'RESULT') return `${t('notice.contract')} ${index + 1}`;
    if (type === 'COMPETITION') return `${t('notice.version')} ${index + 1}`;
    return '';
  };

  return (
    <div
      className={classNames(styles.accordionItem, {
        [styles.highlighted]: links.some((link) => link.activePage)
      })}
    >
      <div
        className={styles.accordionHeader}
        role="button"
        tabIndex={0}
        onClick={onToggle}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            onToggle();
          }
        }}
      >
        <span
          className={classNames(styles.circle, {
            [styles.activeCircle]: links.some((link) => link.activePage)
          })}
        ></span>
        <div className={styles.headerContent}>
          <span className={styles.title}>{title}</span>
          <div className={styles.linkCountContainer}>
            <span className={styles.linkCount}>{links.length}</span>
            <Document className={styles.documentIcon} />
          </div>
        </div>
        <div className={styles.chevron}>
          {isActive ? <ChevronUp /> : <ChevronDown />}
        </div>
      </div>

      {isActive && (
        <div className={styles.accordionContent}>
          {links.length === 0 ? (
            <div className={styles.emptyMessage}>
              {t('timeline.empty_message', { type: title.toLowerCase() })}
            </div>
          ) : (
            <>
              {displayedLinks.map((link, index) => (
                <Link
                  key={index}
                  to={buildUrl([NOTICES, link.id])}
                  onClick={handleLinkClick}
                  className={classNames(styles.linkItem, {
                    [styles.activeLink]: link.activePage
                  })}
                  onMouseEnter={() => setHoveredLinkIndex(index)}
                  onMouseLeave={() => setHoveredLinkIndex(null)}
                >
                  {link.activePage || hoveredLinkIndex === index ? (
                    <Document
                      data-testid="normalIcon"
                      className={styles.linkDocumentIconLarge}
                    />
                  ) : (
                    <DocumentFaded
                      data-testid="fadedIcon"
                      className={styles.linkDocumentIconLarge}
                    />
                  )}
                  <div className={styles.linkTextContainer}>
                    <span className={styles.linkName}>{link.name}</span>
                    <span className={styles.linkDate}>
                      {formatDate(link.date)}{' '}
                      {getLabel(type, index, links.length)}
                    </span>
                  </div>
                  {hoveredLinkIndex === index && (
                    <ArrowRight className={styles.arrowIcon} />
                  )}
                </Link>
              ))}
              {links.length > 5 && (
                <button
                  onClick={toggleShowAll}
                  className={styles.showMoreButton}
                >
                  <span>
                    {showAll
                      ? t('timeline.show_less')
                      : t('timeline.show_more')}
                  </span>
                  {showAll ? (
                    <ArrowUp className={styles.icon} />
                  ) : (
                    <ArrowDown className={styles.icon} />
                  )}
                </button>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ProcurementTimeline;
