import React, { ForwardedRef, ReactElement, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SuggestItem,
  SuggestionListItem,
  SuggestionResponse,
  SearchTypes
} from 'models';
import { ReactComponent as MagnifierIcon } from '../../../images/icon/magnifier_white.svg';
import { ReactComponent as CategoryCpv } from '../../../images/icon/category_cpv.svg';
import { ReactComponent as BuisnessCenter } from '../../../images/icon/buisness_center.svg';
import { ReactComponent as LocationCity } from '../../../images/icon/location_city.svg';
import css from './SuggestionList.module.scss';
import SuggestionItem from './suggestionItem/SuggestionItem';
import classNames from 'classnames';
import EmptySearchItem from './suggestionItem/EmptySearchItem';
import SearchSelector from 'pages/frontpage/elements/searchSelector/SearchSelector';

interface SuggestionListProps {
  readonly query: string;
  readonly suggestionList: SuggestionListItem[];
  readonly focusedIndex: number;
  readonly handleQuerySearch: () => void;
  readonly handleSelectOption: (option: string, categoryName: string) => void;
  readonly suggestions?: SuggestionResponse;
  readonly isFrontPage?: boolean;
}

// Icon mapping
const categoryIcons = {
  cpvCodesLabel: (
    <CategoryCpv
      aria-label="Icon for cpvCodesLabel"
      aria-hidden="false"
      className={css.icon}
    />
  ),
  cpvCodesId: (
    <CategoryCpv
      aria-label="Icon for cpvCodesId"
      aria-hidden="false"
      className={css.icon}
    />
  ),
  buyer: (
    <LocationCity
      aria-label="Icon for buyer"
      aria-hidden="false"
      className={css.icon}
    />
  ),
  winner: (
    <BuisnessCenter
      aria-label="Icon for winner"
      aria-hidden="false"
      className={css.icon}
    />
  )
};

// Utility function for finding the focused index
const findFocusedIndex = (
  suggestionList: SuggestionListItem[],
  item: SuggestItem,
  category: string
) => {
  const index = suggestionList?.findIndex(
    (suggestionItem) =>
      suggestionItem.value === item.value &&
      suggestionItem.categoryName === category
  );
  return (index ?? 0) + 1;
};

// Utility to render items
const renderItems = (
  items: SuggestItem[],
  categoryKey: string,
  suggestionList: SuggestionListItem[],
  focusedIndex: number,
  handleSelectOption: (id: string, category: string) => void,
  query: string
) =>
  items.map((item: SuggestItem) => (
    <SuggestionItem
      key={
        categoryKey === 'cpvCodesLabel' && 'cpvCodesId' ? item.id : item.value
      }
      item={item}
      focused={
        focusedIndex === findFocusedIndex(suggestionList, item, categoryKey)
      }
      categoryName={categoryKey}
      onClick={() => handleSelectOption(item.id, categoryKey)}
      focusedIndex={findFocusedIndex(suggestionList, item, categoryKey)}
      query={query}
    />
  ));

export default forwardRef(function SuggestionList(
  props: SuggestionListProps,
  ref: ForwardedRef<HTMLUListElement>
): ReactElement {
  const {
    query,
    suggestionList,
    focusedIndex,
    handleQuerySearch,
    handleSelectOption,
    suggestions,
    isFrontPage
  } = props;
  const { t } = useTranslation('translations');
  const hasResults = !!suggestionList.length;
  const orderedCategories = ['cpvCodesLabel', 'cpvCodesId', 'buyer', 'winner'];
  const searchCheckboxes: SearchTypes[] = [
    {
      type: 'PLANNING',
      label: 'frontpage.planned_proc'
    },
    {
      type: 'COMPETITION',
      label: 'frontpage.active_proc'
    },
    {
      type: 'RESULT',
      label: 'frontpage.result_proc'
    }
  ];

  return (
    <ul
      ref={ref}
      id="suggestion_box"
      className={css.suggestion_box}
      role="listbox"
      data-cy="suggestion_result"
      tabIndex={-1}
      key="suggestion_box"
      aria-activedescendant={hasResults ? `option_${focusedIndex}` : 'option_0'}
    >
      {!!suggestions &&
        query.length >= 2 &&
        orderedCategories.map((categoryKey) => {
          const category = suggestions[categoryKey];
          if (!category || category.items.length === 0) return null;

          return (
            <React.Fragment key={categoryKey}>
              <li
                tabIndex={-1}
                role="presentation"
                aria-hidden="true"
                data-cy="suggestions"
                className={classNames(css.suggestion_group, css.categoryName)}
              >
                {categoryIcons[categoryKey]} {/* Optimized icon rendering */}
                {t(`common.${categoryKey}`)}
              </li>
              {renderItems(
                category.items,
                categoryKey,
                suggestionList,
                focusedIndex,
                handleSelectOption,
                query
              )}
            </React.Fragment>
          );
        })}
      {query.length >= 2 && (
        <li
          role="option"
          id="option_0"
          aria-selected={focusedIndex === 0}
          tabIndex={-1}
          onClick={handleQuerySearch}
          className={classNames(
            css.search_button,
            focusedIndex === 0 && css.focused
          )}
          onKeyDown={(event) => event.key === 'Enter' && handleQuerySearch()}
        >
          {t('search.show_all_results')} "{query}"
          <MagnifierIcon aria-hidden="true" className={css.magnifier_icon} />
        </li>
      )}

      {query.length <= 1 && isFrontPage && (
        <>
          <SearchSelector
            focusedIndex={focusedIndex}
            searchCheckboxes={searchCheckboxes}
            aria-hidden="true"
          />
          <EmptySearchItem
            key={'empty-search-box'}
            data-cy="empty_search-box"
            aria-selected={focusedIndex === 3}
            focusedIndex={focusedIndex}
            aria-hidden="true"
          />
        </>
      )}
    </ul>
  );
});
