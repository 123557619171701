import {
  SearchBody,
  SortOptionType,
  StatusType,
  ProcurementType,
  HitsPerPageType,
  Facets,
  Procurement,
  Status,
  ContractNatureType
} from 'models';

function searchParametersFromURL(url: URLSearchParams): SearchBody {
  return {
    numHitsPerPage: url.get('hitsPerPage')
      ? (parseInt(url.get('hitsPerPage') as string, 10) as HitsPerPageType)
      : 20,
    page: url.get('page') ? parseInt(url.get('page') as string, 10) : 1,
    searchString: url.get('searchString') ?? '',
    sortBy: (url.get('sortBy') as SortOptionType) ?? 'RELEVANCE',
    facets: {
      cpvCodesLabel: {
        checkedItems: url.get('cpvCodesLabel')?.split(',') ?? []
      },
      cpvCodesId: {
        checkedItems: url.get('cpvCodesId')?.split(',') ?? []
      },
      type: {
        checkedItems: (url.get('type')?.split(',') as ProcurementType[]) ?? []
      },
      status: {
        checkedItems: (url.get('status')?.split(',') as StatusType[]) ?? []
      },
      contractNature: {
        checkedItems:
          (url.get('contractNature')?.split(',') as ContractNatureType[]) ?? []
      },
      publicationDate: {
        from: url.get('fromDate') ?? null,
        to: url.get('toDate') ?? null
      },
      location: {
        checkedItems: url.get('location')?.split(',') ?? []
      },
      buyer: {
        checkedItems: url.get('buyer')?.split(',') ?? []
      },
      winner: {
        checkedItems: url.get('winner')?.split(',') ?? []
      }
    }
  };
}

export function facetsFromUrl(
  url: URLSearchParams,
  selectedCheckboxes?: string[]
): Facets {
  const facets: Facets = {
    cpvCodesLabel: { checkedItems: url.get('cpvCodesLabel')?.split(',') ?? [] },
    cpvCodesId: { checkedItems: url.get('cpvCodesId')?.split(',') ?? [] },
    type: {
      checkedItems: (url.get('type')?.split(',') as ProcurementType[]) ?? []
    },
    status: {
      checkedItems: (url.get('status')?.split(',') as StatusType[]) ?? []
    },
    contractNature: {
      checkedItems:
        (url.get('contractNature')?.split(',') as ContractNatureType[]) ?? []
    },
    publicationDate: {
      from: url.get('fromDate') ?? null,
      to: url.get('toDate') ?? null
    },
    location: {
      checkedItems: url.get('location')?.split(',') ?? []
    },
    buyer: {
      checkedItems: url.get('buyer')?.split(',') ?? []
    },
    winner: {
      checkedItems: url.get('winner')?.split(',') ?? []
    }
  };

  if (selectedCheckboxes && selectedCheckboxes.length > 0) {
    facets.type.checkedItems = selectedCheckboxes as ProcurementType[];
    if (selectedCheckboxes.includes(Procurement.COMPETITION))
      facets.status.checkedItems = [Status.ACTIVE];
  }

  return facets;
}

export { searchParametersFromURL };
