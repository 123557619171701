import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ClockIcon } from '../../../../../images/icon/alarm-clock.svg';
import { ReactComponent as SavingsIcon } from '../../../../../images/icon/savings.svg';
import { ReactComponent as LocationIcon } from '../../../../../images/icon/location.svg';
import { ReactComponent as CalendarIcon } from '../../../../../images/icon/calendar.svg';
import css from '../NoticeCard.module.scss';
import { Hit } from 'models';
import { formatDate, formatNumbers } from 'shared/utils';
import useLocations from 'shared/useLocations';
import { useMemo } from 'react';

interface Props {
  readonly notice: Hit;
  readonly isCompetition?: boolean;
}

export default function IconLine({ notice, isCompetition }: Props) {
  const { t } = useTranslation('translations');
  const { locationId = [], estimatedValue, deadline, publicationDate } = notice;
  const { flatLocations: locations } = useLocations();

  const estimatedValueText = estimatedValue
    ? `${formatNumbers(estimatedValue.amount.toFixed(0)).toString()} ${
        estimatedValue.currencyCode
      }`
    : t('common.not_specified');

  const deadlineSoonMessage = useMemo(() => {
    if (!deadline) return '';
    const targetDate = new Date(deadline);
    const diffInMillis = targetDate.getTime() - Date.now();
    const oneWeekInMillis = 7 * 24 * 60 * 60 * 1000;
    return diffInMillis > 0 && diffInMillis <= oneWeekInMillis
      ? `(${t('search.card.deadline_soon')})`
      : '';
  }, [deadline, t]);

  const locationName = useMemo(() => {
    if (!locations || !locationId.length) return t('common.not_specified');
    return (
      locationId
        .map((id) => locations.find((location) => location.id === id)?.name)
        .filter(Boolean)
        .join(', ') || t('common.not_specified')
    );
  }, [locationId, locations, t]);

  const getDeadline = () => {
    return deadline ? formatDate(deadline) : t('common.not_specified');
  };

  return (
    <div className={css.iconline}>
      <p
        className={css.location}
        data-cy="notice-card-location"
        aria-label={`${t('filter.location')}: ${locationName}`}
      >
        <LocationIcon />
        {locationName}
      </p>
      <p
        className={css.est_value}
        aria-label={`${t('filter.value')}: ${estimatedValue}`}
      >
        <SavingsIcon />
        {estimatedValueText}
      </p>
      <p
        className={css.issue_date}
        aria-label={`${t('filter.date')}: ${formatDate(publicationDate)}`}
      >
        <CalendarIcon />
        {formatDate(publicationDate)}
      </p>
      {isCompetition && (
        <p
          data-cy="notice-card-deadline"
          className={classnames(
            css.deadline,
            deadlineSoonMessage && css.soon_deadline
          )}
          aria-label={`${t(
            'DEADLINE'
          )}: ${getDeadline()} ${deadlineSoonMessage}`}
        >
          <ClockIcon />
          {`${getDeadline()} ${deadlineSoonMessage}`}
        </p>
      )}
    </div>
  );
}
